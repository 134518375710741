<template>
  <div @click="dzDelJs">
    <div class="content">
      <div class="contentBox">
        <div class="contHeadBox">
          <div class="boxs" v-for="(item, index) in typeList">
            <div class="box" :class="index < typeOn ? 'boxOn' : ''">
              <div class="name">{{ item.name1 }}</div>
              <div class="name">{{ item.name2 }}</div>
            </div>
            <div class="texts" :class="index < typeOn ? 'textsOn' : ''">
              <div class="iconfont icon-jian1 txt"></div>
              <div class="iconfont icon-jian1 txt"></div>
              <div class="iconfont icon-jian1 txt"></div>
              <div class="iconfont icon-jian1 txt"></div>
              <div class="iconfont icon-jian1 txt"></div>
              <div class="iconfont icon-jian1 txt"></div>
            </div>
          </div>
        </div>
        <div class="ztBox">
          <div class="title" v-if="info.is_return == 1">当前状态：已退款</div>
          <div class="title" v-if="info.is_return == 0">
            当前状态：{{
              info.status == 0
                ? "已取消"
                : info.status == 1
                ? "待付款"
                : info.status == 2
                ? "待发货"
                : info.status == 3
                ? "已发货"
                : info.status == 4
                ? "已完成"
                : "已评价"
            }}
          </div>
          <div class="textBox">
            <div class="box">
              <div class="name">订单编号：</div>
              <div class="txt">{{ info.ordernum }}</div>
            </div>
          </div>
          <div class="textBox" v-if="info.status >= 3">
            <div class="box">
              <div class="name">国内物流承运方：</div>
              <div class="txt">{{ info.logistics }}</div>
            </div>
            <div class="box">
              <div class="name">物流编号：</div>
              <div class="txt">{{ info.logistics_number }}</div>
            </div>
          </div>
          <div class="wlList">
            <div class="box" v-for="item in info.express_content">
              <div class="ysBox">
                <div class="yuan"></div>
                <div class="shu"></div>
              </div>
              <div class="time">{{ item.AcceptTime }}</div>
              <div class="txt">{{ item.AcceptStation }}</div>
            </div>
          </div>
        </div>
        <div class="nrCont">
          <div class="title">订单详情</div>
          <div class="nrBox">
            <div class="txtBox">
              <div class="name">收货人信息:</div>
              <div class="txt">
                {{ addressInfo.name }}（{{ addressInfo.mobile }}）
              </div>
            </div>
            <div class="txtBox">
              <div class="name">收货地址:</div>
              <div class="txt">
                {{ addressInfo.province }}{{ addressInfo.city
                }}{{ addressInfo.area }}{{ addressInfo.address }}
              </div>
              <div
                class="addressBox"
                @click.stop="dzShowJs"
                v-if="info.status == 1"
              >
                <div class="addTxt">选择</div>
                <div class="iconfont icon-enter" :class="xuanzhuan"></div>
              </div>
            </div>
            <div class="dzBox" v-if="dzShow">
              <div class="dzCont">
                <div class="dzHead">
                  <div class="name">选择配送地址</div>
                  <div class="iconfont icon-close" @click="dzDelJs"></div>
                </div>
                <div class="dzList scroll">
                  <div
                    class="dzLi"
                    :class="index == addressOn ? 'on' : ''"
                    v-for="(item, index) in addresslist"
                    @click="addresslistClick(item, index)"
                  >
                    <div class="mr" v-if="item.is_default == 1">默认</div>
                    <div class="name">
                      {{ item.name }}<span>{{ item.mobile }}</span>
                    </div>
                    <div class="txt c-2">
                      {{ item.province }}{{ item.city }}{{ item.area
                      }}{{ item.address }}
                    </div>
                  </div>
                  <div class="dzLi" @click="addressshow = true">
                    <div class="dzLiBox dis-cent">
                      <div class="iconfont icon-add"></div>
                      <div class="name">新增地址</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="txtBox">
              <div class="name">实付金额:</div>
              <div class="txt1">¥{{ info.money }}</div>
            </div>
          </div>
        </div>
        <div class="contList">
          <div class="title">产品信息</div>
          <div class="cont">
            <div class="contHead">
              <div class="time">{{ info.create_time }}</div>
              <div class="txt">订单号:{{ info.ordernum }}</div>
            </div>
            <div class="contBox" v-for="item in carlist">
              <div class="cpBox w40">
                <div class="img">
                  <img
                    :src="$imgUrl + item.part.resources[0].path_name"
                    alt=""
                  />
                </div>
                <div class="box">
                  <div class="name">{{ item.productname }}</div>
                  <div class="numtxt">购买数量：{{ item.num }}件</div>
                  <div class="txts">
                    <div class="txt" v-for="chi in item.specs">{{ chi }}</div>
                  </div>
                  <div class="fhtxt">发货时间：{{ fhTime }}前发货</div>
                </div>
              </div>
              <div class="text w20">
                <div class="name">￥{{ item.price }}</div>
                <div class="txt">(包邮)</div>
              </div>
              <div class="text w20">
                {{
                  item.status == 0
                    ? "退款审核中"
                    : item.status == 1
                    ? "退款审核完成"
                    : item.status == 2
                    ? "退货中"
                    : item.status == 3
                    ? "已退款"
                    : item.status == -1
                    ? "退款失败"
                    : info.status == 0
                    ? "已取消"
                    : info.status == 1
                    ? "待付款"
                    : info.status == 2
                    ? "待发货"
                    : info.status == 3
                    ? "已发货"
                    : info.status == 4
                    ? "已完成"
                    : "已评价"
                }}
              </div>
              <div class="text w20">
                <template
                  v-if="
                    item.status != 0 &&
                    item.status != 1 &&
                    item.status != 2 &&
                    item.status != 3
                  "
                >
                  <div
                    class="butBox"
                    @click="tkshowClick(item)"
                    v-if="
                      info.status == 2 ||
                      info.status == 3 ||
                      info.status == 4 ||
                      info.status == 5
                    "
                  >
                    退款
                  </div>
                </template>
                <div
                  class="butBox"
                  @click="dhshowClick(item)"
                  v-if="item.status == 1"
                >
                  上传物流
                </div>
              </div>
            </div>
          </div>
          <div class="saveBoxs" v-if="info.status != 0">
            <div class="saveBtn1" @click="delClick" v-if="info.status == 1">
              取消订单
            </div>
            <div class="saveBtn" v-if="info.status == 1" @click="payClick">
              提交付款
            </div>
            <div class="saveBtn" v-if="info.status == 3" @click="shouhuoClick">
              确认收货
            </div>
            <div class="saveBtn" v-if="info.status == 4" @click="shouhuoClick">
              立即评价
            </div>
          </div>
        </div>
      </div>
    </div>
    <TjDizhi v-if="addressshow" @show="addressShowDelJs" />
    <a-modal v-model="shouhuovisible" title="提示" @ok="shouhuohandleOk">
      <p>是否确认收货</p>
    </a-modal>
    <a-modal v-model="visible" title="提示" @ok="handleOk">
      <p>是否确定取消此订单</p>
    </a-modal>
    <Daohao v-if="dhShow" @show="dhdelClick" />
  </div>
</template>

<script>
import TjDizhi from "../component/tjDizhi.vue";
import { Form, Space, Button, Modal } from "ant-design-vue";
import Daohao from "../component/danhao.vue";
export default {
  components: {
    Daohao,
    TjDizhi,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-button": Button,
    "a-space": Space,
    "a-modal": Modal,
  },
  name: "",
  data() {
    return {
      dhShow: false,
      shouhuovisible: false,
      visible: false,
      dzShow: false,
      xuanzhuan: "",
      typeOn: 0,
      typeList: [
        {
          name1: "提交",
          name2: "订单",
        },
        {
          name1: "付款",
          name2: "成功",
        },
        // {
        //   name1: "商品",
        //   name2: "出库",
        // },
        {
          name1: "等待",
          name2: "收货",
        },
        {
          name1: "订单",
          name2: "完成",
        },
      ],
      addressshow: false,
      addressInfo: {},
      addresslist: [],
      addressOn: 0,
      info: {},
      carlist: [],
      type: "",
      fhTime: "",
      wlId: 0,
    };
  },
  created() {
    this.id = this.$route.query.id;
    if (!this.id) {
      this.addressList();
    }
    this.get();
  },
  methods: {
    dhdelClick(data) {
      this.dhShow = false;
      if (data) {
        data.id = this.wlId;
        this.$post("/web/refund_logistics", data).then((res) => {
          console.log(res);
          if (res.data.status) {
            this.$message.success("提交成功");
            this.get();
          } else {
            this.$message.warning(res.data.msg);
          }
        });
      }
    },
    dhshowClick(item) {
      this.wlId = item.id;
      this.dhShow = true;
    },
    tkshowClick(item) {
      this.$router.push({
        path: "/tuikuan",
        query: {
          id: this.id,
          chrid: item.id,
        },
      });
    },
    payClick() {
      this.$router.push({
        path: "/pay",
        query: {
          id: this.id,
        },
      });
    },
    shouhuohandleOk() {
      this.$post("/web/order_shou", {
        id: Number(this.id),
      }).then((res) => {
        console.log(res);
        this.shouhuovisible = false;
        if (res.data.status) {
          this.$message.success("确认收货成功");
          this.get();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    shouhuoClick() {
      this.shouhuovisible = true;
    },
    handleOk() {
      this.$post("/web/order_cancel", {
        id: Number(this.id),
      }).then((res) => {
        console.log(res);
        this.visible = false;
        if (res.data.status) {
          this.$message.success(res.data.msg);
          this.get();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    delClick() {
      this.visible = true;
    },
    get() {
      this.$get("/web/order_get", {
        id: this.id,
      }).then((res) => {
        if (res.data.status >= 3) {
          res.data.express_content = JSON.parse(res.data.express_content);
        }
        this.info = res.data;
        this.fhTime = this.$moment(res.data.create_time)
          .add(3, "days")
          .format("MM月DD日HH:mm");
        this.typeOn = res.data.status;
        if (!res.data.address) {
          this.address_default_get();
        } else {
          this.addressInfo = res.data;
        }
        for (let i = 0; i < res.carlist.length; i++) {
          const e = res.carlist[i];
          if (e.spec.spec) {
            e.spec.spec = JSON.parse(e.spec.spec);
            e.specs = [];
            for (const j in e.spec.spec) {
              if (e.spec.spec.hasOwnProperty.call(e.spec.spec, j)) {
                const ej = e.spec.spec[j];
                if (j != "name") {
                  e.specs.push(ej);
                }
              }
            }
          }
        }
        this.carlist = res.carlist;
        this.addressList(res.data.address_id);
        console.log(res);
      });
    },
    addressShowDelJs(data) {
      this.addressshow = false;
      if (data) {
        this.addresslist = [];
        this.addressList();
      }
    },
    addresslistClick(item, i) {
      this.$post("/web/order_address_update", {
        order_id: Number(this.id),
        address_id: Number(item.id),
      }).then((res) => {
        if (res.data.status) {
          this.addressInfo = item;
          this.addressOn = i;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    address_default_get() {
      this.$get("/web/address_default_get").then((res) => {
        this.addressInfo = res.data;
      });
    },
    addressList(id) {
      this.$get("/web/address", {
        page: -1,
      }).then((res) => {
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            const e = res.data[i];
            this.addresslist.push(e);
            if (id) {
              if (id == e.id) {
                this.addressOn = i;
              }
            }
          }
        }
      });
    },
    dzShowJs() {
      this.dzShow = true;
      this.xuanzhuan = "xuanzhuan90";
    },
    dzDelJs() {
      this.dzShow = false;
      this.xuanzhuan = "xuanzhuan-90";
    },
  },
  mounted() {},
  computed: {},
};
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .contentBox {
    width: 1440px;
    margin: auto;
    background-color: #fff;
    border-radius: 5px;
    box-sizing: border-box;
    .contHeadBox {
      display: flex;
      align-items: center;
      padding: 30px;
      padding-top: 30px;
      border-bottom: 1px solid #ebebeb;
      .boxs {
        height: 80px;
        display: flex;
        align-items: center;
      }
      .box {
        border: 2px solid #ebebeb;
        box-sizing: border-box;
        border-radius: 50%;
        height: 65px;
        width: 65px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        flex-direction: column;
        color: #666;
      }
      .boxOn {
        color: #f00303;
        border: 2px solid #f00303;
      }
      .texts {
        padding: 0 15px;
        display: flex;
        align-items: center;
        color: #c9c9c9;
        .txt {
          font-size: 14px;
          margin-right: 3px;
          font-weight: 600;
        }
        .txt:last-child {
          margin-right: 0;
        }
      }
      .textsOn {
        color: #f00303;
      }
      .boxs:last-child {
        .texts {
          display: none;
        }
      }
    }
    .ztBox {
      padding: 30px;
      border-bottom: 1px solid #ebebeb;
      .title {
        font-size: 28px;
        margin-bottom: 20px;
        font-weight: 600;
      }
      .textBox {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .box {
          display: flex;
          align-items: center;
          margin-right: 15px;
          .name {
            color: #9c9c9c;
          }
        }
      }
      .wlList {
        margin-top: 20px;
        .box {
          display: flex;
          .ysBox {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-right: 10px;
            position: relative;
            top: 7px;
            .yuan {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background-color: #c9c9c9;
            }
            .shu {
              width: 1px;
              height: calc(100% - 8px);
              background-color: #c9c9c9;
            }
          }
          .time {
            margin-right: 20px;
          }
          .txt {
            padding-bottom: 20px;
            color: #666;
          }
        }
        .box:last-child {
          .ysBox {
            .shu {
              display: none;
            }
            .yuan {
              background-color: #e70101;
            }
          }
          .time {
            color: #e70101;
          }
          .txt {
            padding-bottom: 0;
            color: #e70101;
          }
        }
        // .box:first-child {
        //   .ysBox {
        //     .yuan {
        //       background-color: #e70101;
        //     }
        //   }
        //   .time {
        //     color: #e70101;
        //   }
        //   .txt {
        //     color: #e70101;
        //   }
        // }
      }
    }
    .nrCont {
      padding: 30px;
      .title {
        font-weight: 600;
      }
      .nrBox {
        background-color: whitesmoke;
        padding: 25px;
        border-radius: 5px;
        margin-top: 20px;
        .txtBox {
          margin-bottom: 15px;
          display: flex;
          .name {
            color: #9c9c9c;
            float: left;
            margin-right: 10px;
          }
          .txt {
            float: left;
          }
          .txt1 {
            float: left;
            font-weight: 600;
            color: #e70101;
          }
          .addressBox {
            display: flex;
            align-items: center;
            float: left;
            font-size: 12px;
            background-color: #1783e9;
            justify-content: center;
            width: 45px;
            height: 25px;
            border-radius: 5px;
            color: #fff;
            position: relative;
            margin-left: 10px;
            top: -2px;
            cursor: pointer;
            .addTxt {
              position: relative;
              top: -1px;
            }
            .iconfont {
              font-size: 10px;
            }
          }
          .xuanzhuan90 {
            animation: zhuan90 0.5s both;
          }
          @keyframes zhuan90 {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(90deg);
            }
          }
          .xuanzhuan-90 {
            animation: zhuan-90 0.5s both;
          }
          @keyframes zhuan-90 {
            0% {
              transform: rotate(90deg);
            }
            100% {
              transform: rotate(0deg);
            }
          }
        }
        .txtBox:last-child {
          margin-bottom: 0;
        }
      }
    }
    .contList {
      margin-top: 10px;
      padding: 0 30px;
      padding-bottom: 30px;
      .title {
        font-weight: 600;
        margin-bottom: 20px;
      }
      .cont {
        border-radius: 5px;
        background-color: white;
        overflow: hidden;
        border: 1px solid whitesmoke;
        box-sizing: border-box;
        margin-bottom: 10px;
        .contHead {
          height: 45px;
          display: flex;
          background-color: #ececec;
          align-items: center;
          padding-left: 20px;
          .time {
            font-weight: 700;
          }
          .txt {
            margin-left: 15px;
            color: #888;
          }
        }
        .fhBag {
          background-color: rgb(224, 239, 255);
        }
        .honBag {
          background-color: rgb(255, 224, 224);
        }
        .contBox {
          display: flex;
          align-items: center;
          .cpBox {
            padding: 20px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            .img {
              width: 80px;
              border-radius: 5px;
              height: 80px;
              overflow: hidden;
              img {
                object-fit: cover;
              }
            }
            .box {
              width: calc(100% - 80px);
              height: 80px;
              padding-left: 10px;
              .numtxt {
                font-size: 12px;
                color: #888;
              }
              .txts {
                display: flex;
                align-items: center;
                margin-top: 4px;
                margin-bottom: 1px;
                .txt {
                  font-size: 10px;
                  padding: 1px 6px;
                  border-radius: 4px;
                  border: 1px solid #ebebeb;
                  margin-right: 5px;
                }
              }
              .fhtxt {
                font-size: 12px;
                color: #333;
              }
            }
          }
          .text {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 10px 0;
            .name {
              font-weight: 600;
            }
            .txt {
              font-weight: 600;
              color: #888;
            }
            .butBox {
              padding: 3px 12px;
              border: 1px solid #c9c9c9;
              border-radius: 4px;
              font-size: 12px;
              cursor: pointer;
              margin-bottom: 10px;
            }
            .butBox:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
      .saveBoxs {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .saveBtn {
          cursor: pointer;
          width: 90px;
          background-color: #e90707;
          color: #fff;
          font-weight: 600;
          font-size: 14px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 45px;
        }
        .saveBtn1 {
          cursor: pointer;
          width: 90px;
          font-weight: 600;
          font-size: 14px;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 45px;
          border: 1px solid #c9c9c9;
          color: #888;
          margin-right: 15px;
          box-sizing: border-box;
        }
      }
    }
  }
}
.dzBox {
  position: relative;
  margin-top: 10px;
  width: 100%;
  .dzCont {
    width: calc(50% - 0px);
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 0 4px 2px #ebebeb;
    z-index: 9;
    padding: 10px;
    border-radius: 10px;
    background-color: #fff;
    .dzHead {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 10px;
      .name {
        font-weight: 600;
      }
      .iconfont {
        font-size: 14px;
        font-weight: 600;
        position: relative;
        top: 1px;
        cursor: pointer;
      }
    }
    .dzList {
      overflow: auto;
      height: 180px;
      padding-right: 5px;
      .dzLi {
        float: left;
        width: calc(50% - 7.5px);
        margin-right: 15px;
        margin-bottom: 15px;
        height: 80px;
        box-sizing: border-box;
        border-radius: 8px;
        background-color: #f3f6f8;
        padding: 12px 12px 9px;
        margin-right: 12px;
        font-size: 12px;
        position: relative;
        border: 1px solid transparent;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .mr {
          position: absolute;
          top: 4px;
          right: 4px;
          font-size: 12px;
          line-height: 12px;
          color: #fff;
          background-color: rgba(0, 0, 0, 0.2);
          padding: 4px;
          border-radius: 4px;
        }
        .name {
          font-weight: 600;
          span {
            margin-left: 5px;
            color: #9c9c9c;
            font-size: 400;
          }
        }
        .txt {
          color: #7c889c;
        }
        .dzLiBox {
          width: 100%;
          height: 100%;
        }
      }
      .dzLi:hover {
        background-color: #fff;
        border: 1px solid #ff0800;
      }
      .on {
        background-color: #fff;
        border: 1px solid #ff0800;
      }
      .dzLi:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
