<template>
  <div>
    <div class="bj">
      <div class="bjContent">
        <div class="bjCont">
          <div class="iconfont icon-close cha" @click="delClick"></div>
          <div class="cont">
            <div class="contHead">物流单号</div>
            <div class="contBox">
              <a-textarea
                v-model="value"
                style="margin-top: 15px"
                placeholder="填写物流单号~"
                :auto-size="{ minRows: 3, maxRows: 8 }"
              />
            </div>
          </div>
          <div class="gnBox">
            <div class="btn qx" @click="delClick">取消</div>
            <div class="btn" @click="plClick">提交</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Select, Form, Input, Button, Upload, Icon } from "ant-design-vue";
export default {
  components: {
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-button": Button,
    "a-textarea": Input.TextArea,
    "a-upload": Upload,
    "a-icon": Icon,
  },
  data() {
    return {
      size: "default",
      value: "",
      headers: {
        authorization: "authorization-text",
      },
      form: {
        username: "",
        password: "",
      },
    };
  },
  mounted() {},
  activated() {},
  methods: {
    delClick() {
      this.$emit("show");
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          console.log("Received values of form: ", values);
        }
      });
    },
    handleChange(value) {
      console.log(`Selected: ${value}`);
    },
    popupScroll() {
      console.log("popupScroll");
    },
    plClick() {
      this.$emit("show", { express_number: this.value });
    },
  },
};
</script>
<style lang="less" scoped>
.bjContent {
  display: flex;
  width: 100%;
  justify-content: center;

  .bjCont {
    background-color: #fff;
    width: 600px;
    margin-top: 90px;
    border-radius: 10px;
    position: relative;

    .cha {
      position: absolute;
      cursor: pointer;
      top: 10px;
      font-weight: 600;
      right: 20px;
      color: #9c9c9c;
    }

    .cont {
      padding: 20px 20px 10px;

      .contHead {
        font-size: 16px;
        font-weight: 600;
        color: #333;
      }

      .contBox {
        padding-top: 0;
        padding-bottom: 10px;

        .name {
          margin-bottom: 5px;

          span {
            color: #f30000;
          }
        }

        .tishi {
          line-height: 150%;
          color: #bfbfbf;
          font-size: 12px;
          margin-top: 5px;
          text-align: justify;
        }

        .upImg {
          margin-top: 10px;

          .text {
            margin-left: 20px;
            font-size: 12px;
          }

          .txt {
            margin-left: 5px;
            font-size: 12px;
            color: #999;
          }

          .imgs {
            display: flex;
            margin-top: 15px;
            flex-wrap: wrap;

            .img {
              width: calc(20% - 12px);
              margin-right: 15px;
              padding-top: calc(20% - 12px);
              position: relative;
              margin-bottom: 15px;
              overflow: unset;

              img {
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 5px;
              }

              .close {
                position: absolute;
                top: -3px;
                right: -3px;
                background-color: #b3b3b3;
                border-radius: 50%;
                width: 15px;
                height: 15px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 10px;
                color: #fff;
                cursor: pointer;
              }
            }

            .img:nth-child(5n) {
              margin-right: 0;
            }
          }
        }
      }
    }

    .gnBox {
      border-top: 1px solid #d9d9d9;
      padding: 10px 24px;
      overflow: hidden;
      display: flex;
      justify-content: flex-end;

      .btn {
        color: #fff;
        background: #e23;
        border: 1px solid #e23;
        padding: 5px 15px;
        font-size: 12px;
        border-radius: 5px;
        margin-left: 15px;
        cursor: pointer;
      }

      .qx {
        background: unset;
        border: 1px solid rgb(180, 180, 180);
        color: #666;
      }
    }
  }
}
</style>
